export const data = [
  {
    type:1,
    name: "唐镇社区党群服务中心",
    user: "梅老师",
    mobile: "58964186",
    img: "https://pic.netbian.com/uploads/allimg/240721/001822-1721492302c5d4.jpg",
    time: "工作日 08:30~17:00",
    desc: "唐镇社区党群服务中心坐落于顾唐路3150号，阵地面积6050平方米。近年来，在上级部门的指导下，中心不断推进一体化体系功能建设，对文化中心、社区学校、群团组织、志愿服务中心等资源进行整合，将党群中心打造成“一站式”的便民服务中心和党员群众喜闻乐见的红色主阵地。",
    address: "上海市浦东新区顾唐路3150号",
    lng: 121.669233,
    lat: 31.207084,
    serve: [
      {
        icon: "https://img.amsos.cn/images/tzsq1.jpg",
        name: "一网通办",
      },
      {
        icon: "https://img.amsos.cn/images/tzsq2.jpg",
        name: "应急药箱",
      },
      {
        icon: "https://img.amsos.cn/images/tzsq3.jpg",
        name: "无线网络",
      },
      {
        icon: "https://img.amsos.cn/images/tzsq4.jpg",
        name: "冷热饮水",
      },
      {
        icon: "https://img.amsos.cn/images/tzsq5.jpg",
        name: "应急充电",
      },
      {
        icon: "https://img.amsos.cn/images/tzsq6.jpg",
        name: "党员组织关系转接",
      },
      {
        icon: "https://img.amsos.cn/images/tzsq7.jpg",
        name: "党务咨询",
      },
      {
        icon: "https://img.amsos.cn/images/tzsq8.jpg",
        name: "红色加油站",
      },
      {
        icon: "https://img.amsos.cn/images/tzsq9.jpg",
        name: "",
      },
      {
        icon: "https://img.amsos.cn/images/tzsq10.jpg",
        name: "",
      },
      {
        icon: "https://img.amsos.cn/images/tzsq11.jpg",
        name: "",
      },
      {
        icon: "https://img.amsos.cn/images/tzsq12.jpg",
        name: "",
      },
    ],
    serve2: [
      {
        icon: require("../assets/ywtb.png"),
        name: "一网通办",
      },
      {
        icon: require("../assets/yjyx.png"),
        name: "应急药箱",
      },
      {
        icon: require("../assets/wxwl.png"),
        name: "无线网络",
      },
      {
        icon: require("../assets/lrys.png"),
        name: "冷热饮水",
      },
      {
        icon: require("../assets/yjcd.png"),
        name: "应急充电",
      },
      {
        icon: require("../assets/dyzzgx.png"),
        name: "党员组织关系转接",
      },
      {
        icon: require("../assets/dwzx.png"),
        name: "党务咨询",
      },
      {
        icon: require("../assets/hsjyz.png"),
        name: "红色加油站",
      },
      {
        icon: require("../assets/xgyst.png"),
        name: "小哥议事厅",
      },
    ],
  },
  {
    type:1,
    name: "诚礼居民区党群服务站",
    user: "龚颖",
    mobile: "50206108",
    img: "https://pic.netbian.com/uploads/allimg/240721/001822-1721492302c5d4.jpg",
    time: "工作日 08:30~16:30；节假日9:00-11:00,13:30-16:00",
    desc: "诚礼居民区党群服务站位于诚礼路298弄25号，面积800平方米。坚持党建引领，对辖区内外资源深挖细磨、整合盘活，凝心打造“诚礼故事汇”红色主阵地品牌，聚力将诚礼居民区党群服务站打造成“一站式”的便民服务站，激发党员、群众、新就业群体等全人群参与活动的热情，促进自我提升、反哺社区、自我实现，共同打造一个有温度的红色家园。",
    address: "上海市浦东新区唐镇诚礼路298弄25号",
    lng: 121.668389,
    lat: 31.225735,
    serve: [
      {
        icon: require("../assets/dqfw.png"),
        name: "党群服务",
      },

      {
        icon: require("../assets/bmfw.png"),
        name: "便民服务",
      },
      {
        icon: require("../assets/whfw.png"),
        name: "文化服务",
      },
      {
        icon: require("../assets/tsfw.png"),
        name: "特色服务",
      },
      {
        icon: require("../assets/gdqt.png"),
        name: "更多其他",
      },
    ],
    serve2: [],
  },
  {
    name: "齐友居民区党群服务站",
    user: "王伟丽",
    mobile: "68798628",
    img: "https://pic.netbian.com/uploads/allimg/240721/001822-1721492302c5d4.jpg",
    time: "工作日 08:30~16:30",
    desc: "齐友党群服务站位于创新中路380弄24号，共有上下两层，使用面积800平方米。齐友党群服务站涵盖齐友社区党校、“友”家童趣，“友”家邻里，“友”家置物，“友”家观展等板块，努力满足全年龄段不同人群的品质生活需求。",
    address: "上海市浦东新区创新中路380弄24号",
    lng: 121.664545,
    lat: 31.207135,
    serve: [
      {
        icon: "https://img.amsos.cn/images/qy1.jpg",
        name: "党务咨询",
      },
      {
        icon: "https://img.amsos.cn/images/qy2.jpg",
        name: "辅助器械",
      },
      {
        icon: "https://img.amsos.cn/images/qy3.jpg",
        name: "血压测量",
      },
      {
        icon: "https://img.amsos.cn/images/qy4.jpg",
        name: "体重测量",
      },
      {
        icon: "https://img.amsos.cn/images/qy5.jpg",
        name: "童趣空间",
      },
      {
        icon: "https://img.amsos.cn/images/qy6.jpg",
        name: "便民缝补",
      },
      {
        icon: "https://img.amsos.cn/images/qy7.jpg",
        name: "公共展览",
      },

     
    ],
    serve2: [
      {
        icon: require("../assets/hdwzx.png"),
        name: "党务咨询",
      },
      {
        icon: require("../assets/hfzqx.png"),
        name: "辅助器械",
      },
      {
        icon: require("../assets/hxycl.png"),
        name: "血压测量",
      },
      {
        icon: require("../assets/htzcl.png"),
        name: "体重测量",
      },
      {
        icon: require("../assets/hbbw.png"),
        name: "童趣空间",
      },
      {
        icon: require("../assets/hbmfb.png"),
        name: "便民缝补",
      },
      {
        icon: require("../assets/hggzl.png"),
        name: "公共展览",
      },

      {
        icon: require("../assets/hflzx.png"),
        name: "法律咨询",
      },
    ],
  },
  {
    type:1,
    name: "培元居民区党群服务站",
    user: "徐晓斌",
    mobile: "68792051",
    img: "https://pic.netbian.com/uploads/allimg/240721/001822-1721492302c5d4.jpg",
    time: "工作日 08:30~16:30",
    desc: "培元新苑党群服务站位于创新中路593弄27号，阵地面积722.85平方米，共设两层。通过整合小区内外资源，探索“党建＋服务圈”的工作模式，汇集服务、文体、宣传教育、文创公益等各类便民、惠民、利民于一体的综合服务阵地。",
    address: "上海市浦东新区创新中路593弄27号",
    lng: 121.66886,
    lat: 31.211369,
    serve: [
      {
        icon: "https://img.amsos.cn/images/py.jpg",
        name: "党务咨询",
      },

      {
        icon: "https://img.amsos.cn/images/py1.jpg",
        name: "辅助器械",
      },
      {
        icon: "https://img.amsos.cn/images/py2.jpg",
        name: "血压测量",
      },
      {
        icon: "https://img.amsos.cn/images/py3.jpg",
        name: "体重测量",
      },
      {
        icon: "https://img.amsos.cn/images/py4.jpg",
        name: "红色加油站",
      },
     
      {
        icon: "https://img.amsos.cn/images/py6.jpg",
        name: "便民服务",
      },
      {
        icon: "https://img.amsos.cn/images/py7.jpg",
        name: "公共展览",
      },

      {
        icon: "https://img.amsos.cn/images/py8.jpg",
        name: "法律咨询",
      }
     
    ],
    serve2: [
      {
        icon: require("../assets/hdwzx.png"),
        name: "党务咨询",
      },
      {
        icon: require("../assets/hfzqx.png"),
        name: "辅助器械",
      },
      {
        icon: require("../assets/hxycl.png"),
        name: "血压测量",
      },
      {
        icon: require("../assets/htzcl.png"),
        name: "体重测量",
      },
      {
        icon: require("../assets/hhsjyz.png"),
        name: "红色加油站",
      },
      {
        icon: require("../assets/hbbw.png"),
        name: "宝宝屋",
      },
      {
        icon: require("../assets/hbmfw.png"),
        name: "便民服务",
      },
      {
        icon: require("../assets/hggzl.png"),
        name: "公共展览",
      },

      {
        icon: require("../assets/hflzx.png"),
        name: "法律咨询",
      },
    ],
  },
  {
    type:1,
    name: "唐丰苑居民区党群服务站",
    user: "徐韵",
    mobile: "58903235",
    img: "https://pic.netbian.com/uploads/allimg/240721/001822-1721492302c5d4.jpg",
    time: "工作日 08:30~16:30",
    desc: "唐丰苑居民区党群服务站位于创新西路333弄126号，共有上下两层，阵地面积约1100平方米。依托文化客厅建设基础进一步丰富“家在唐镇”理念，围绕“强党建、优空间、建平台、促治理”，不断完善为民服务机制，为社区居民提供优质便捷的各类服务。",
    address: "上海市浦东新区创新西路333弄126号",
    lng: 121.652052,
    lat: 31.198071,
    serve: [
     

      {
        icon: "https://img.amsos.cn/images/tfy1.jpg",
        name: "辅助器械",
      },
      {
        icon: "https://img.amsos.cn/images/tfy2.jpg",
        name: "健康小屋",
      },
      {
        icon: "https://img.amsos.cn/images/tfy3.jpg",
        name: "便民服务",
      },
      {
        icon: "https://img.amsos.cn/images/tfy4.jpg",
        name: "党务咨询",
      },
      {
        icon: "https://img.amsos.cn/images/tfy5.jpg",
        name: "法律咨询",
      },
      // {
      //   icon: "https://img.amsos.cn/images/tfy6.jpg",
      //   name: "活动空间使用",
      // },
    ],
    serve2: [
      {
        icon: require("../assets/hdwzx.png"),
        name: "党务咨询",
      },
      {
        icon: require("../assets/hfzqx.png"),
        name: "辅助器械",
      },
      {
        icon: require("../assets/hjkxw.png"),
        name: "健康小屋",
      },
      {
        icon: require("../assets/hbmfw.png"),
        name: "便民服务",
      },
      {
        icon: require("../assets/hggzl.png"),
        name: "公共展览",
      },

      {
        icon: require("../assets/hflzx.png"),
        name: "法律咨询",
      },
      {
        icon: require("../assets/hhdkjsy.png"),
        name: "活动空间使用",
      },
    ],
  },
  {
    type:1,
    name: "民丰村党群服务中心",
    user: "龚燕宁",
    mobile: "58961715",
    img: "https://pic.netbian.com/uploads/allimg/240721/001822-1721492302c5d4.jpg",
    time: "工作日 08:30~16:30",
    desc: "民丰村党群服务站位于新前路1308号，建筑总面积2000平方米。设有人大代表联络点，退役军人事务站，新实践文明实践基地，议事厅等。",
    address: "上海市浦东新区唐镇新前路1308号",
    lng: 121.641806,
    lat: 31.208311,
    serve: [
      {
        icon: "https://img.amsos.cn/images/mf1.jpg",
        name: "党务咨询",
      },

      {
        icon: "https://img.amsos.cn/images/mf2.jpg",
        name: "红色加油站",
      },
      {
        icon: "https://img.amsos.cn/images/mf3.jpg",
        name: "法律咨询",
      },
      {
        icon: "https://img.amsos.cn/images/mf4.jpg",
        name: "食品安全工作站",
      },
      {
        icon: "https://img.amsos.cn/images/mf5.jpg",
        name: "红色加油站",
      },
      {
        icon: "https://img.amsos.cn/images/mf6.jpg",
        name: "法律咨询",
      },
      {
        icon: "https://img.amsos.cn/images/mf7.jpg",
        name: "食品安全工作站",
      },
    ],
    serve2: [
      {
        icon: require("../assets/hdwzx.png"),
        name: "党务咨询",
      },
      {
        icon: require("../assets/hhsjyz.png"),
        name: "红色加油站",
      },

      {
        icon: require("../assets/hflzx.png"),
        name: "法律咨询",
      },
      {
        icon: require("../assets/hspaq.png"),
        name: "食品安全工作站",
      },
    ],
  },
 

  {
    type:1,
    name: "唐镇人大代表之家",
    user: "徐雨婷",
    mobile: "13524272862",
    img: "https://pic.netbian.com/uploads/allimg/240721/001822-1721492302c5d4.jpg",
    time: "工作日 08:30~16:30",
    desc: "唐镇人大代表之家位于中国上海市浦东新区唐镇，是一个专门为当地人大代表提供服务和活动的平台，旨在加强人大代表与人民群众之间的联系，推动全过程人民民主的实践。作为基层立法联系点之一，唐镇人大代表之家也参与了地方性法规的征求意见工作，例如对《浦东新区市场主体登记确认制若干规定》和《浦东新区促进商事调解若干规定》等法规提出意见和建议，体现了其在地方立法过程中的积极作用。总之，唐镇人大代表之家是一个集代表接待、民主协商、法规咨询、教育宣传等功能于一体的综合性平台，对于深化基层民主、提升社区治理效能具有重要意义。",
    address: "上海市浦东新区唐陆公路3312号",
    lng: 121.654983,
    lat: 31.208106,
    serve: [
      {
        icon: "https://img.amsos.cn/images/rddbzj1.jpg",
        name: "应急药箱",
      },

      {
        icon: "https://img.amsos.cn/images/rddbzj2.jpg",
        name: "血压测量",
      },
      {
        icon: "https://img.amsos.cn/images/rddbzj3.jpg",
        name: "冷热饮水",
      },
      {
        icon: "https://img.amsos.cn/images/rddbzj4.jpg",
        name: "应急充电",
      },
      {
        icon: "https://img.amsos.cn/images/rddbzj5.jpg",
        name: "党员组织关系转换",
      },
      {
        icon: "https://img.amsos.cn/images/rddbzj6.jpg",
        name: "党员组织关系转换",
      },
    ],
    serve2: [
      {
        icon: require("../assets/hyjyx.png"),
        name: "应急药箱",
      },
      {
        icon: require("../assets/hxycl.png"),
        name: "血压测量",
      },
      {
        icon: require("../assets/hlrys.png"),
        name: "冷热饮水",
      },
      {
        icon: require("../assets/hyjcd.png"),
        name: "应急充电",
      },
      {
        icon: require("../assets/hdyzzgx.png"),
        name: "党员组织关系转接",
      },
    ],
  },
  {
    name: "虹三村党群服务站",
    user: "唐盈彬",
    mobile: "50630862",
    img: "https://pic.netbian.com/uploads/allimg/240721/001822-1721492302c5d4.jpg",
    time: "工作日 08:30~16:30",
    desc: "虹三村党群服务中心坐落于景雅路221号，阵地面积360平方米。虹三村党群服务中心贯彻落实“平安、美丽、健康、快乐、智能”为目标，汇集教育、展示、管理、服务于一体的综合阵地，实现资源共享，致力服务升级，努力为村民提供优质便捷的各类服务。",
    address: "上海市浦东新区唐镇景雅路221号",
    lng: 121.673083,
    lat: 31.254367,
    serve: [
      {
        icon: "https://img.amsos.cn/images/hsc1.jpg",
        name: "党务咨询",
      },
      {
        icon: "https://img.amsos.cn/images/hsc2.jpg",
        name: "党员组织关系转换",
      },

      {
        icon: "https://img.amsos.cn/images/hsc3.jpg",
        name: "血压测量",
      },
      {
        icon: "https://img.amsos.cn/images/hsc4.jpg",
        name: "艺人舞台",
      },
      {
        icon: "https://img.amsos.cn/images/hsc5.jpg",
        name: "便民服务",
      },

      {
        icon: "https://img.amsos.cn/images/hsc6.jpg",
        name: "法律咨询",
      },
      {
        icon: "https://img.amsos.cn/images/hsc7.jpg",
        name: "无线网络",
      },
      {
        icon: "https://img.amsos.cn/images/hsc8.jpg",
        name: "打印复印",
      },
    ],
    serve2: [
      {
        icon: require("../assets/hdwzx.png"),
        name: "党务咨询",
      },
      {
        icon: require("../assets/hdyzzgx.png"),
        name: "党员组织关系转接",
      },

      {
        icon: require("../assets/hxycl.png"),
        name: "血压测量",
      },

      {
        icon: require("../assets/hyrwt.png"),
        name: "艺人舞台",
      },
      {
        icon: require("../assets/hbmfw.png"),
        name: "便民服务",
      },
      {
        icon: require("../assets/hflzx.png"),
        name: "法律咨询",
      },
      {
        icon: require("../assets/hwxwl.png"),
        name: "无线网络",
      },
      {
        icon: require("../assets/hdyfy.png"),
        name: "打印复印",
      },
    ],
  },
  {
    type:1,
    name: "东唐党群服务站",
    user: "张璐",
    mobile: "58960120",
    img: "https://pic.netbian.com/uploads/allimg/240721/001822-1721492302c5d4.jpg",
    time: "工作日 08:30~16:30",
    desc: "东唐党群服务站位于唐镇镇域最热闹的唐镇路唐陆公路街角处，是一个集休闲、娱乐、办公、便民等功能复合多元的一体化服务枢纽，也是街区嵌入式的公共服务阵地，于2024年5月20日试运营。作为唐镇15分钟社区生活圈规划中的街区微型“综合体”，中心占地面积621平方米，围绕“全龄共享、友好善治”理念，进一步统筹资源、拓展功能，主要分为党群服务区、红鸭咖啡区、助老就餐区、便民服务区、24小时政务服务区、社区学校、童趣空间和户外职工驿站等功能空间。中心在做好基本民生服务需求保障的同时，积极发挥社会服务资源的专业化支撑作用，每月定期配送医疗咨询、法律援助、文化体育、亲子阅读、理发缝补等服务项目，并整合街区党群组织及优质社会资源，开展公益讲座、职工技能培训、手作类活动等，力争实现阵地再拓展，服务再升温，构筑全域主体和人群均能共享的、家门口时尚感与烟火气并存的“街角幸福圈”。",
    address: "上海市浦东新区唐陆公路3256号",
    lng: 121.647455,
    lat: 31.224974,
    serve: [
      {
        icon: "https://img.amsos.cn/images/dt1.jpg",
        name: "一网通办",
      },
      {
        icon: "https://img.amsos.cn/images/dt2.jpg",
        name: "应急药箱",
      },

      {
        icon: "https://img.amsos.cn/images/dt3.jpg",
        name: "无线网络",
      },
      {
        icon: "https://img.amsos.cn/images/dt4.jpg",
        name: "冷热饮水",
      },
      {
        icon: "https://img.amsos.cn/images/dt5.jpg",
        name: "应急充电",
      },

      {
        icon: "https://img.amsos.cn/images/dt6.jpg",
        name: "社区食堂",
      },
     
    ],
    serve2: [
      {
        icon: require("../assets/hywtb.png"),
        name: "一网通办",
      },
      {
        icon: require("../assets/hyjyx.png"),
        name: "应急药箱",
      },
      {
        icon: require("../assets/hwxwl.png"),
        name: "无线网络",
      },
      {
        icon: require("../assets/hlrys.png"),
        name: "冷热饮水",
      },
      {
        icon: require("../assets/hyjcd.png"),
        name: "应急充电",
      },
      {
        icon: require("../assets/hsqst.png"),
        name: "社区食堂",
      },
      {
        icon: require("../assets/hflzx.png"),
        name: "法律咨询",
      },
      {
        icon: require("../assets/hjkxw.png"),
        name: "健康咨询",
      },
      {
        icon: require("../assets/hxycl.png"),
        name: "血压测量",
      },
      {
        icon: require("../assets/htzcl.png"),
        name: "体重测量",
      },
      {
        icon: require("../assets/hbbw.png"),
        name: "童趣空间",
      },
      {
        icon: require("../assets/hbmfb.png"),
        name: "便民缝补",
      },
      {
        icon: require("../assets/hbmlf.png"),
        name: "便民理发",
      },
      {
        icon: require("../assets/hbmxx.png"),
        name: "便民修鞋",
      },
      {
        icon: require("../assets/hbmxjd.png"),
        name: "便民修家电",
      },
      {
        icon: require("../assets/hggzl.png"),
        name: "公共展览",
      },
      {
        icon: require("../assets/hhwzgyz.png"),
        name: "户外职工驿站",
      },
      {
        icon: require("../assets/hydkj.png"),
        name: "阅读空间",
      },
      {
        icon: require("../assets/hdyycjy.png"),
        name: "党员远程教育",
      },
    ],
  },
]
