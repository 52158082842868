<template>
  <div>
    <Home v-if="isShow"></Home>
    <index v-else></index>
  </div>
</template>

<script>
import Home from "./Home.vue"
import index from "./index.vue"
export default {
  components: {Home,index},
  data() {
    return {
      isShow:true,
      
    }
  },
  computed: {},
  watch: {},
  methods: {},
  created() {
    this.isShow = window.innerWidth>1000?true:false;
    window.addEventListener("resize", (event) => {
      this.isShow = window.innerWidth>1000?true:false;

    });
  },
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
}
</script>
<style lang="scss" scoped></style>
