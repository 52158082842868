export const data1 = [
  // 一个例子
  {
    type: "服务",
    name: "健康小屋",
    service: "唐丰苑居民区党群服务站",
    user: "徐韵",
    mobile: "58903235",
    img: ["https://img.amsos.cn/images/jkxw.jpg"],
    time: "工作日 08:30~16:30",
    desc: "供辖区居民测量血压、身高、体重、中医养生、健康咨询等服务。",
    address: "上海市浦东新区创新西路333弄126号",
    lng: 121.652052,
    lat: 31.198071,
    num: 20,
  },
  //下面
  {
    type: "服务",
    name: "童趣空间",
    service: "齐友居民区党群服务站",
    user: "王伟丽",
    mobile: "68798628",
    img: ["https://img.amsos.cn/images/tqkj.jpg"],
    time: "工作日 08:30~16:30",
    desc: "为小朋友提供乐高积木、书籍等供小朋友休闲娱乐。",
    address: "上海市浦东新区创新中路380弄24号",
    lng: 121.664545,
    lat: 31.207135,
    num: 20,
  },
  {
    type: "服务",
    name: "宝宝屋",
    service: "培元居民区党群服务站",
    user: "徐晓斌",
    mobile: "68792051",
    img: ["https://img.amsos.cn/images/pybbw.jpg"],
    time: "工作日 08:30~16:30",
    desc: "为区域幼儿家庭提供托育服务，使随手“寄娃”成为社区的新风尚。",
    address: "上海市浦东新区创新中路593弄27号",
    lng: 121.66886,
    lat: 31.211369,
    num: 20,
  },
  {
    type: "治理",
    name: "“欢聚议唐”议事厅",
    service: "唐镇人大代表之家",
    user: "徐雨婷",
    mobile: "13524272862",
    img: ["https://img.amsos.cn/images/rdyst.jpg"],
    time: "工作日 08:30~16:30",
    desc: "为唐镇各类人群提供协商议事空间，在议事厅中形成协商议事工作闭环。",
    address: "上海市浦东新区唐陆公路3312号",
    lng: 121.654983,
    lat: 31.208106,
    num: 30,
  },
  // {
  //   type: "治理",
  //   name: "议事厅",
  //   service: "民丰村党群服务中心",
  //   user: "龚燕宁",
  //   mobile: "58961715",
  //   img: ["https://img.amsos.cn/images/yst.jpg"],
  //   time: "工作日 08:30~16:30",
  //   desc: "协商议事共享圈，助力解决群众“烦心事”，邻里“自治”“共治”。提供法律咨询、民调解等。容纳人数20人。",
  //   address: "上海市浦东新区唐镇新前路1308号",
  //   lng: 121.641806,
  //   lat: 31.208311,
  //   num: 20,
  // },
  {
    type: "政治",
    name: "党员组织关系转接",
    service: "虹三村党群服务站",
    user: "唐盈彬",
    mobile: "50630862",
    img: ["https://img.amsos.cn/images/dyzzgxzj.jpg"],
    time: "工作日 08:30~16:30",
    desc: "为党员提供各类党务咨询，办理党员组织关系转接。",
    address: "上海市浦东新区唐镇景雅路221号",
    lng: 121.673083,
    lat: 31.254367,
    num: "无限制",
  },
  {
    type: "政治",
    name: "社区学校",
    service: "东唐党群服务站",
    user: "张璐",
    mobile: "58960120",
    img: ["https://img.amsos.cn/images/sqxx.jpg"],
    time: "每日 08:30~18:30",
    desc: "提供入党宣誓、党课宣讲、“三会一课”等多功能服务。",
    address: "上海市浦东新区唐陆公路3256号",
    lng: 121.647455,
    lat: 31.224974,
    num: 50,
  },
  {
    type: "政治",
    name: "入党宣誓墙",
    service: "唐镇社区党群服务中心",
    user: "梅老师",
    mobile: "58964186",
    img: ["https://img.amsos.cn/images/rdxsq.png"],
    time: "工作日 08:30~16:30",
    desc: "开展党员教育，重温入党誓词。",
    address: "上海市浦东新区顾唐路3150号",
    lng: 121.669233,
    lat: 31.207084,
    num: "无限制",
  },
]
