<template>
  <div class="home">
    <div class="logo"></div>
    <div class="header">
      <span> <i>家在唐镇 暖心社区</i> </span>
    </div>
    <div class="main">
      <div class="tuijian">
        <div class="tabs">
          <div
            class="tab"
            :class="{ active: ac === item.name }"
            v-for="(item, index) in tabData"
            @click="tabCli(item, index)"
            :key="item.name"
          >
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>

      <div class="list">
        <template v-for="item in list">
          <div
            class="item"
            v-if="ifShow(item)"
            :key="item.name"
            @click="openD(item)"
          >
            <div class="img">
              <img :src="item.img[0]" alt="" />
              <p>容纳人数：{{ item.num }}</p>
            </div>

            <div class="info">
              <h3>{{ item.name }}</h3>
              <div class="desc">{{ item.desc }}</div>

              <p class="address">地址：{{ item.address }}</p>
              <p class="time">服务时间：{{ item.time }}</p>
              <p class="user">联系人：{{ item.user }}</p>
              <p class="phone">联系电话：{{ item.mobile }}</p>
            </div>
          </div>
        </template>
      </div>

      <div class="detail" v-if="isInfo">
        <span class="close" @click="isInfo = false"></span>

        <div class="bg">
          <div class="img">
            <van-swipe
              :autoplay="3000"
              :show-indicators="false"
              @change="changeS"
            >
              <van-swipe-item v-for="(image, index) in acData.img" :key="index">
                <img v-lazy="image" />
                <div class="bt">
                  <p>
                    {{ acData.name }}<i>/容纳人数：{{ acData.num }}</i>
                  </p>
                  <span><i></i>{{ sIndex }}/{{ acData.img.length }}</span>
                </div>
              </van-swipe-item>
            </van-swipe>
            <!-- <img :src="acSData.img" alt="" srcset="" /> -->
          </div>
          <div class="txt">
            <div
              class="desc"
              style="margin-top: 10px; margin-bottom: 0; color: #333"
            >
              <span>简介：</span> {{ acData.desc }}
            </div>
            <p class="address">
              地址：<i>{{ acSData.address }}</i>
            </p>
            <p class="zd">
              服务阵地：<i>{{ acSData.name }}</i>
            </p>
            <p class="time">
              服务时间：<i>{{ acSData.time }}</i>
            </p>
            <p class="user">
              联系人：<i>{{ acSData.user }}</i>
            </p>
            <p class="phone">
              联系电话：<i>{{ acSData.mobile }}</i>
            </p>
            <div class="desc">{{ acSData.desc }}</div>
            <h4>服务站更多空间展示</h4>
            <div class="serve">
              <div
                class="serve-item"
                v-for="item in acSData.serve"
                :key="item.name"
              >
                <el-image
                  style="width: 172px; height: 112px"
                  :src="item.icon"
                  :preview-src-list="acSData.serve.map((e) => e.icon)"
                >
                </el-image>
                <!-- <img :src="item.icon" alt="" srcset="" /> -->
                <!-- <p>{{ item.name }}</p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="container" class="map"></div>
    <p class="support">技术支持：上海唐巢</p>
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader"
// @ is an alias to /src
import { _isMobile } from "@/utils/util"
import { data } from "@/utils/data"
import { data1 } from "@/utils/data1"
export default {
  name: "Home",
  components: {},
  data() {
    return {
      map: {},
      ac: "全部",
      acData: {},
      acSData: {},
      tabData: [
        {
          name: "全部",
        },
        {
          name: "政治",
        },
        {
          name: "治理",
        },
        {
          name: "服务",
        }
       
      ],
      list: data1,
      img: [
        "https://pic.netbian.com/uploads/allimg/240721/001822-1721492302c5d4.jpg",
        "https://pic.netbian.com/uploads/allimg/240721/001822-1721492302c5d4.jpg",
      ],
      serve: [
        {
          icon: "https://pic.netbian.com/uploads/allimg/240721/001822-1721492302c5d4.jpg",
          name: "应急药箱",
        },
        {
          icon: "https://pic.netbian.com/uploads/allimg/240721/001822-1721492302c5d4.jpg",
          name: "应急药箱",
        },
        {
          icon: "https://pic.netbian.com/uploads/allimg/240721/001822-1721492302c5d4.jpg",
          name: "无线网络",
        },
        {
          icon: "https://pic.netbian.com/uploads/allimg/240721/001822-1721492302c5d4.jpg",
          name: "冷热饮水",
        },
        {
          icon: "https://pic.netbian.com/uploads/allimg/240721/001822-1721492302c5d4.jpg",
          name: "应急充电",
        },
      ],
      isInfo: false,
      sIndex: 1,
    }
  },
  created() {
    this.initMap()
  },
  methods: {
    ifShow(item) {
      if (this.ac === "全部") {
        return true
      } else {
        if (item.type === this.ac) {
          return true
        } else {
          return false
        }
      }
    },
    changeS(e) {
      this.sIndex = e + 1
    },
    tabCli(item, index) {
      this.ac = item.name
    },
    initMap() {
      AMapLoader.load({
        key: "744a8f67f97df2af0bebaf0141fb937d", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "1.4.15", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [],
      }).then(() => {
        this.map = new AMap.Map("container", {
          resizeEnable: true,
          zooms: _isMobile() ? [10, 20] : [10, 20],
          zoom: _isMobile() ? 12 : 14,
          center: [121.655819, 31.235469],
          pitch: 70, // 地图俯仰角度，有效范围 0 度- 83 度
          viewMode: "2D", // 地图模式,
        })
        this.addMaker()
      })
    },
    addMaker() {
      this.list.forEach((e) => {
        let markerContent = `<div class="s-item">
          <img src="${e.img[0]}"/>
          <span>${e.name}</span>
          </div>`
        let marker = new AMap.Marker({
          position: new AMap.LngLat(e.lng, e.lat),
          content: markerContent,
          anchor: "top-center",
          offset: new AMap.Pixel(0, -63),
        })
        marker.data = e
        this.map.add(marker)
        let _this = this
        marker.on("click", function (w) {
          _this.acData = w.target.data
          let arr = data.filter((e) => e.name === _this.acData.service)
          if (arr.length > 0) {
            _this.acSData = arr[0]
          }
          _this.isInfo = true
        })
      })
    },
    openD(item) {
      this.acData = item
      let arr = data.filter((e) => e.name === this.acData.service)
      if (arr.length > 0) {
        this.acSData = arr[0]
      }
      this.isInfo = true
    },
  },
}
</script>
<style lang="scss" scoped>
.map {
  width: 100%;
  height: 100vh;
}
.header {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  height: 237px;
  width: 100%;
  text-align: center;
  background: linear-gradient(
    180deg,
    rgba(232, 8, 7, 0.42) 0%,
    rgba(232, 8, 7, 0) 100%
  );
  span {
    position: relative;
    display: inline-block;
    margin-top: 58px;
    width: 752px;
    height: 72px;
    line-height: 72px;
    text-align: center;
    background: linear-gradient(
      90deg,
      rgba(243, 0, 0, 0) 0%,
      #ea0000 51%,
      rgba(226, 0, 0, 0) 100%
    );

    i {
      position: relative;
      z-index: 11;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      color: #ffffff;
    }
  }
  span::after {
    content: "";
    position: absolute;
    top: 35px;
    left: 50px;
    width: 120px;
    height: 1px;
    background: rgba(255, 255, 255, 0.6);
    z-index: 10;
  }
  span::before {
    content: "";
    position: absolute;
    top: 35px;
    right: 50px;
    width: 120px;
    height: 1px;
    background: rgba(255, 255, 255, 0.6);
    z-index: 10;
  }
}
.logo {
  position: fixed;
  top: 46px;
  right: 72px;
  width: 122px;
  height: 122px;
  background: #fff;
  border-radius: 42px;
  background: url(../assets/img/logo.png) no-repeat center;
  background-size: cover;
  z-index: 10;
}
.main {
  position: fixed;
  width: 596px;
  background: #fff;
  border-radius: 20px;
  top: 135px;
  left: 20px;
  bottom: 40px;
  z-index: 12;
  overflow: hidden;
  .tuijian {
    height: 64px;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.08) 0%,
      #ffe4dd 47%,
      #ffffff 95%
    );
    margin-bottom: 20px;
  }
  .tabs {
    display: flex;
    width: 500px;
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 10px;
    .tab {
      flex: 1;
      padding-top: 8px;
      text-align: center;
      box-sizing: border-box;
      cursor: pointer;

      span {
        display: block;
        font-size: 24px;
        color: #666666;
        line-height: 28px;
      }
    }
    .tab:last-child {
      margin-right: 0;
    }
    .active {
      position: relative;
      span {
        color: #de290f;
      }
      span::before {
        content: "";
        display: inline-block;
        width: 16px;
        height: 3px;
        background: #de290f;
        border-radius: 4px 4px 4px 4px;
        position: absolute;
        left: 50%;
        margin-left: -8px;
        bottom: -8px;
      }
    }
  }
  .list::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .list {
    height: calc(100% - 100px);
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    .item {
      display: flex;
      margin-bottom: 20px;
      padding: 0 20px;
      cursor: pointer;
      .img {
        width: 200px;
        height: 164px;
        object-fit: cover;
        margin-right: 16px;
        border-radius: 6px;
        overflow: hidden;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        p {
          position: absolute;
          width: 100%;
          height: 48px;
          line-height: 48px;
          left: 0;
          bottom: 0;
          background: linear-gradient(
            360deg,
            rgba(0, 0, 0, 0.8) 1%,
            rgba(0, 0, 0, 0) 100%
          );
          position: absolute;
          text-align: center;
          font-size: 16px;
          color: #ffffff;
        }
      }
      .info {
        flex: 1;
        h3 {
          font-weight: bold;
          font-size: 20px;
          color: #333333;
          margin-bottom: 4px;
        }
        p {
          font-size: 14px;
          color: #606366;
          margin-bottom: 6px;
          padding-left: 20px;
        }
        .address {
          background: url(../assets/img/address.png) no-repeat left center;
          background-size: 14px;
        }
        .time {
          background: url(../assets/img/time.png) no-repeat left center;
          background-size: 14px;
        }
        .user {
          background: url(../assets/img/user.png) no-repeat left center;
          background-size: 14px;
        }
        .phone {
          background: url(../assets/img/phone.png) no-repeat left center;
          background-size: 14px;
        }
        .desc {
          font-size: 14px;
          color: #333333;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          margin-bottom: 10px;
        }
      }
    }
  }
}

/deep/.s-item {
  white-space: nowrap;
  display: flex;
  align-items: center;
  background: #fff;
  padding: 5px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  border-radius: 22px 22px 22px 22px;
  border: 1px solid #ffffff;
  position: relative;
  img {
    display: inline-block;
    width: 33px;
    height: 33px;
    border: 2px solid #cc0500;
    border-radius: 50%;
    margin-right: 8px;
    object-fit: cover;
  }
}
/deep/ .s-item::after {
  content: "";
  position: absolute;
  left: 50%;
  margin-left: -13px;
  bottom: -25px;
  border: 14px solid transparent;
  border-top-color: #fff;
}
/deep/ .s-item::before {
  display: inline-block;
  content: "";
  position: absolute;
  left: 50%;
  margin-left: -10px;
  bottom: -35px;
  width: 20px;
  height: 20px;
  background: url('https://img.amsos.cn/images/aixin.png') no-repeat center;
  background-size: cover;
}
/deep/ .s-item span {
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  display: inline-block;
  padding-right: 5px;
}
.detail {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99;
  top: 0;
  left: 0;
  font-size: 0;
  .close {
    position: fixed;
    top: 84px;
    right: 84px;
    display: inline-block;
    width: 80px;
    height: 80px;
    background: rgba(0, 0, 0, 0.8) url(../assets/close.png) no-repeat center;
    background-size: 28px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 999;
  }
  .bg {
    margin: 100px auto;
    width: 976px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    //     .txt::-webkit-scrollbar {
    //   display: none; /* Chrome Safari */
    // }
    .txt {
      height: 400px;
      overflow: auto;
      padding: 0 20px 20px 20px;
      //     scrollbar-width: none; /* firefox */
      // -ms-overflow-style: none; /* IE 10+ */
      p {
        font-weight: 700;
        font-size: 16px;
        color: #666666;
        margin-top: 8px;
        padding-left: 20px;
        i {
          color: #333;
        }
      }
      h4 {
        font-size: 16px;
        color: #333;
      }
      .desc {
        margin: 10px 0;
        font-size: 16px;
        color: #666666;
        line-height: 16px;
        line-height: 24px;
        span {
          font-weight: bold;
        }
      }
      .address {
        background: url(../assets/img/address.png) no-repeat left center;
        background-size: 14px;
      }
      .zd {
        background: url(../assets/img/zd.png) no-repeat left center;
        background-size: 14px;
      }
      .time {
        background: url(../assets/img/time.png) no-repeat left center;
        background-size: 14px;
      }
      .user {
        background: url(../assets/img/user.png) no-repeat left center;
        background-size: 14px;
      }
      .phone {
        background: url(../assets/img/phone.png) no-repeat left center;
        background-size: 14px;
      }
    }
    .img {
      width: 100%;
      height: 320px;
    }
    .van-swipe-item {
      position: relative;

      img {
        width: 100%;
        height: 320px;
        object-fit: cover;
      }
      .bt {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 46px;
        display: flex;
        align-items: center;
        background: rgba(0, 0, 0, 0.5);
        padding: 0 20px;
        box-sizing: border-box;
        p {
          bottom: 8px;
          left: 12px;
          font-weight: 700;
          font-size: 24px;
          color: #ffffff;
          flex: 1;
          i {
            color: #fff;
            font-size: 16px;
            font-weight: normal;
          }
        }
        span {
          width: 67px;
          background: rgba(255, 255, 255, 0.2);
          text-align: center;
          border-radius: 16px 16px 16px 16px;
          font-size: 16px;
          color: #fff;
          padding: 4px 0;
          i {
            display: inline-block;
            width: 16px;
            height: 16px;
            background: url(../assets/img/photo.png) no-repeat center;
            background-size: cover;
            vertical-align: middle;
          }
        }
      }
    }
    h4 {
      font-weight: bold;
      font-size: 24px;
      color: #333333;
      margin-top: 12px;
    }
    .address {
      font-size: 16px;
      color: #606366;
      margin-top: 6px;
      padding-left: 20px;
      background: url(../assets/address.png) no-repeat left center;
      background-size: 14px;
    }
    .s-l {
      display: inline-block;
      margin-top: 6px;
      margin-right: 5px;
      .c-time {
        padding-left: 20px;
        background: url(../assets/c-time.png) no-repeat left center;
        background-size: 16px;
      }
      .c-phone {
        padding-left: 20px;
        background: url(../assets/c-phone.png) no-repeat left center;
        background-size: 16px;
      }
      .c-user {
        padding-left: 20px;
        background: url(../assets/c-user.png) no-repeat left center;
        background-size: 16px;
      }
      span {
        font-weight: bold;
        font-size: 16px;
        color: #cc0500;
      }
      i {
        font-size: 16px;
        color: #333;
        font-style: normal;
      }
    }
    .desc {
      font-size: 16px;
      color: #333333;
      line-height: 24px;
      margin-top: 6px;
      span {
        font-weight: bold;
      }
    }
    /deep/ .el-image {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .serve {
      font-size: 0;
      .serve-item {
        display: inline-block;
        margin-right: 8px;
        text-align: center;
        margin-top: 8px;
        border-radius: 6px;
        overflow: hidden;

        img {
          width: 172px;
          height: 112px;
          object-fit: cover;
        }
        p {
          margin-top: 6px;

          font-size: 12px;
          color: #333333;
        }
      }
    }
  }
}
/deep/ .amap-logo {
  display: none !important;
}
/deep/ .amap-copyright {
  display: none !important;
}
/deep/ .van-swipe__indicators {
  bottom: 30px;
}
/deep/ .van-swipe__indicator {
  background: #fff;
  opacity: 1;
}
/deep/ .van-swipe__indicator--active {
  background: #cc0500;
}
.support{
  position: fixed;
  width: 100%;
  text-align: center;
  color: #C0C0C0;
  font-size: 12px;
  bottom: 20px;
}
</style>
