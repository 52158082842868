<template>
  <div class="index">
    <div class="gg" v-if="time > 0">
      <span class="gg-times">跳过{{ time }}s</span>
      <img :src="ggUrl" alt="" srcset="" />
    </div>
    <div class="banner">
      <van-swipe :autoplay="3000">
        <van-swipe-item v-for="(image, index) in images" :key="index">
          <img v-lazy="image" />
        </van-swipe-item>
      </van-swipe>
      <span class="logo"></span>
      <!-- <h2>家在唐镇</h2> -->
    </div>
    <div class="i-main">
      <div class="input">
        <div class="search">
          <span></span>
          <form action="javascript:return true">
            <input
              type="search"
              v-model="val"
              @keyup.13="tapToSearch"
              placeholder="请输入关键词"
            />
          </form>
          <i @click="tapToSearch">搜索</i>
        </div>
      </div>
      <div class="tabs">
        <div
          class="tab"
          :class="{ active: ac === item.name }"
          v-for="item in tabData"
          @click="tabCli(item)"
          :key="item.name"
        >
          <span>{{ item.name }}</span>
        </div>
      </div>
      <div class="list">
        <template v-for="item in list">
          <div class="item" v-if="ifShow(item) && isIn(item)" :key="item.name">
            <div class="img" @click="goTo(item)">
              <img :src="item.img[0]" alt="" />
              <p>容纳人数：{{ item.num }}</p>
            </div>
            <div class="info" @click="goTo(item)">
              <h3>{{ item.name }}</h3>
              <div class="desc">
                {{ item.desc }}
              </div>
              <p>地址：{{ item.address }}</p>
              <p>服务时间：{{ item.time }}</p>
            </div>
            <div class="icon">
              <img
                @click="open(item)"
                src="../assets/img/dh.png"
                alt=""
                srcset=""
              />
              <img
                src="../assets/img/daohang.png"
                @click.self="goTo2(item)"
                alt=""
                srcset=""
              />
            </div>
          </div>
        </template>
      </div>
      <div class="none" v-if="showList.length === 0">
        <img src="../assets/img/kong.png" alt="" srcset="" />
        <p>抱歉，没有找到相关信息</p>
      </div>

    </div>
    <p class="support">技术支持：上海唐巢</p>
    <van-popup
      v-model="show"
      closeable
      round
      position="bottom"
      :style="{ height: '30%' }"
    >
      <div class="pop">
        <h5>拨打电话</h5>
        <p>{{ info.mobile }}</p>
        <a :href="tel">立即拨打</a>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Swipe, SwipeItem } from "vant"
import { data1 } from "@/utils/data1"
export default {
  components: { Swipe, SwipeItem },
  data() {
    return {
      ggUrl: "https://img.amsos.cn/images/hyy.jpg",
      val: "",
      sVal: "",
      show: false,
      images: [
        "https://img.amsos.cn/images/lbt1.png",
        "https://img.amsos.cn/images/lbt2.jpg",
        "https://img.amsos.cn/images/lbt3.jpg",
        "https://img.amsos.cn/images/lbt4.jpg",
        "https://img.amsos.cn/images/lbt5.jpg",
        "https://img.amsos.cn/images/lbt6.jpg",
      ],
      ac: "全部",
      info: {},
      tabData: [
        {
          name: "全部",
        },
        {
          name: "政治",
        },
        {
          name: "治理",
        },
        {
          name: "服务",
        },
      ],
      list: [],
      time: 0,
      showList: [{}],
      tel: "",
    }
  },
  computed: {},
  watch: {
    val: function (n, o) {
      if (n === "") {
        this.tapToSearch()
      }
    },
  },
  methods: {
    goTo(item) {
      this.$router.push({
        path: "/info?name=" + item.service,
      })
    },
    goTo2(item) {
      console.log(123)
      this.$router.push({
        path: `/info?name=${item.service}&tab=2`,
      })
    },
    tapToSearch() {
      this.sVal = this.val
      this.showList = []
      let arr = this.list.filter(
        (e) =>
          e.name.indexOf(this.sVal) !== -1 ||
          e.service.indexOf(this.sVal) !== -1 ||
          e.address.indexOf(this.sVal) !== -1
      )
      arr.forEach((e) => {
        if (this.ac === "全部") {
          this.showList.push(e)
        } else if (this.ac === e.type) {
          this.showList.push(e)
        }
      })
    },
    ifShow(item) {
      if (this.ac === "全部") {
        return true
      } else {
        if (item.type === this.ac) {
          return true
        } else {
          return false
        }
      }
    },
    isIn(item) {
      if (this.sVal === "") {
        return true
      }
      if (
        item.name.indexOf(this.sVal) !== -1 ||
        item.service.indexOf(this.sVal) !== -1 ||
        item.address.indexOf(this.sVal) !== -1
      ) {
        return true
      } else {
        return false
      }
    },
    tabCli(item) {
      this.ac = item.name
      this.tapToSearch()
    },
    open(item) {
      this.show = true
      this.info = item
      this.tel = "tel:" + this.info.mobile
    },
  },
  created() {
    this.list = data1
    let inter = setInterval(() => {
      this.time--
      if (this.time === 0) {
        clearInterval(inter)
      }
    }, 1000)
  },
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
}
</script>
<style lang="scss" scoped>
.index {
  height: 100vh;
  box-sizing: border-box;
  padding-bottom: 60px;
  overflow: auto;
}
.banner {
  position: relative;
  h2 {
    position: absolute;
    text-align: center;
    width: 100%;
    left: 0;
    top: 40px;
    height: 44px;
    line-height: 44px;
    font-weight: 700;
    font-size: 16px;
    color: #ffffff;
    background: url(../assets/img/logo.png) no-repeat 16px center;
    background-size: 40px;
  }
}
.i-main {
  background: #fff;
  border-radius: 16px 16px 0px 0px;
  overflow: hidden;
  margin-top: -20px;
  position: relative;
  z-index: 999;
  .input {
    padding: 16px 16px 0 16px;
    box-sizing: border-box;
    .search {
      height: 36px;
      background: #f7f8fa;
      border-radius: 32px 32px 32px 32px;
      box-sizing: border-box;
      padding: 0 0 0 10px;
      display: flex;
      align-items: center;
      span {
        display: inline-block;
        width: 32px;
        height: 16px;
        background: url(../assets/img/search.png) no-repeat center;
        background-size: 16px;
      }
      form {
        height: 100%;
        flex: 1;
      }
      input {
        background: transparent;
        height: 100%;
        width: 100%;
        border: none;
      }
      input::placeholder {
        color: #808080;
      }
      i {
        width: 56px;
        height: 28px;
        background: #e0201f;
        border-radius: 14px 14px 14px 14px;
        text-align: center;
        line-height: 28px;
        font-size: 14px;
        color: #ffffff;
      }
    }
  }
}
.tabs {
  display: flex;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px 10px 16px;
  border-bottom: 1px solid #f3f3f3;
  box-sizing: border-box;
  .tab {
    flex: 1;
    padding-top: 8px;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;

    span {
      display: block;
      font-size: 14px;
      color: #666666;
      line-height: 28px;
    }
  }
  .tab:last-child {
    margin-right: 0;
  }
  .active {
    position: relative;
    span {
      font-weight: 700;
      color: #333333;
      font-size: 16px;
    }
    span::before {
      content: "";
      display: inline-block;
      width: 16px;
      height: 3px;
      background: #de290f;
      border-radius: 4px 4px 4px 4px;
      position: absolute;
      left: 50%;
      margin-left: -8px;
      bottom: -8px;
    }
  }
}
.list {
  padding: 0 12px;
  .item {
    padding: 12px 0;
    border-bottom: 1px solid #f3f3f3;
    box-sizing: border-box;
    display: flex;
    .img {
      position: relative;
      width: 100px;
      height: 82px;
      border-radius: 8px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      p {
        position: absolute;
        width: 100%;
        height: 20px;
        background: rgba(0, 0, 0, 0.5);
        line-height: 20px;
        text-align: center;
        bottom: 0;
        left: 0;
        font-size: 10px;
        color: #ffffff;
      }
    }
    .info {
      margin-left: 10px;
      flex: 1;
      h3 {
        font-weight: 700;
        font-size: 16px;
        color: #000000;
        line-height: 24px;
      }
      .desc {
        font-size: 10px;
        color: #333333;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      p {
        font-size: 10px;
        color: #666666;
        margin-top: 4px;
      }
    }
    .icon {
      margin-left: 20px;
      img {
        display: block;
        width: 24px;
        height: 24px;
        margin-top: 12px;
      }
    }
  }
}
.van-swipe-item {
  img {
    width: 100%;
    height: 209px;
  }
}
/deep/ .van-swipe__indicators {
  bottom: 30px;
}
/deep/ .van-swipe__indicator {
  background: #fff;
  opacity: 1;
}
/deep/ .van-swipe__indicator--active {
  background: #cc0500;
}
.gg {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 9999;
  background: #fff;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .gg-times {
    display: inline-block;
    width: 81px;
    height: 36px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 8px 8px 8px 8px;
    position: fixed;
    top: 60px;
    right: 20px;
    text-align: center;
    line-height: 36px;
    font-size: 14px;
    color: #fff;
  }
}
input[type="search"] {
  -webkit-appearance: none;
}
input::-webkit-search-cancel-button {
  display: none;
}
input[type="search"]::-ms-clear {
  display: none;
}
.none {
  padding: 40px 0;
  width: 100%;
  text-align: center;
  img {
    display: inline-block;
    width: 72px;
    height: 72px;
  }
  p {
    margin-top: 8px;
    font-size: 12px;
    color: #6a7072;
  }
}
.support{
  position: fixed;
  width: 100%;
  text-align: center;
  color: #C0C0C0;
  font-size: 10px;
  bottom: 20px;
}
</style>
